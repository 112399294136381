import { Menu, MenuButton, MenuList, MenuItem } from '@reach/menu-button'
import { Image } from '~/elements/Image/Image'
import { useTranslation } from 'next-i18next'
import { Icon } from 'elements/Icon/Icon'
import { useSession } from 'hooks/useSession'
import { signOut } from 'next-auth/react'
import { Link } from 'elements/Link/Link'
import { toast } from 'react-toastify'
import { Boop } from 'animations/Boop'
import useLocalStorage from '~/hooks/useLocalStorage'
import { CART_ID, DEFAULT_LOCALE } from '~/config/constants'
import { useQueryClient } from '@tanstack/react-query'
import { useGetCustomerQuery } from '@magentoTypes'
import { createMagentoClient } from '~/graphql/magentoClient'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { ButtonMain } from '~/elements/Button/ButtonMain'
import { CioUserScript } from '~/modules/CioUserScript/CioUserScript'

type Props = {}
export const AccountButton = ({}: Props) => {
  const { t } = useTranslation()
  const [session] = useSession({ refetchOnMount: true })
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({
    usePost: false,
    queryClient,
    locale: finalLocale,
  })
  const { data: customerData } = useGetCustomerQuery(magentoClient, undefined, {
    enabled: !!session,
  })

  const toggleMenu = () => {
    document
      .querySelector('#account-menu')
      ?.parentElement?.classList.toggle('force-show-reach-menu')

    document
      .querySelector('#language-menu')
      ?.parentElement?.classList.remove('force-show-reach-menu')
  }

  const handleLogout = async () => {
    try {
      await queryClient.invalidateQueries()
      toast.success(t('Logged out successfully'))
      await signOut({ callbackUrl: '/' })
    } catch (err) {
      toast.error(t('Error logging out'))
    }
  }

  return (
    <>
      {!session ? (
        <Link href="/login?authmode=login" title={t('account')}>
          <div className="grid h-10 w-10 place-content-center rounded-full bg-grey-medium lg:h-auto lg:w-auto lg:rounded-none lg:bg-transparent lg:bg-none">
            <Boop>
              <Image
                src={'/images/Icons/account.svg'}
                width={26}
                height={26}
                placeholder="empty"
                alt="account-icon"
                style={{ minWidth: '24px' }}
              />
            </Boop>
          </div>
        </Link>
      ) : (
        <>
          <Menu id="account-menu" className="relative z-[99999999]">
            <MenuButton onTouchStart={toggleMenu} onMouseEnter={toggleMenu} className="block">
              <div className="grid h-10 w-10 place-content-center rounded-full bg-grey-medium lg:h-auto lg:w-auto lg:rounded-none lg:bg-transparent lg:bg-none">
                <Boop>
                  <Image
                    src={'/images/Icons/account.svg'}
                    width={26}
                    height={26}
                    placeholder="empty"
                    alt="account-icon"
                    style={{ minWidth: '24px' }}
                  />
                </Boop>
              </div>
            </MenuButton>
            <MenuList
              className="absolute -left-[225px] top-4 min-w-[245px] !border-0 p-6 shadow-[0px_4px_24px_rgba(0,0,0,0.15)]"
              onMouseLeave={toggleMenu}
            >
              <div className="pl-6 pb-2 text-h4-mobile font-extralight">
                {t('Welcome')}
                {customerData?.customer?.firstname && `, ${customerData?.customer?.firstname}!`}
              </div>
              <MenuItem className="!hover:bg-white font-extralight" onSelect={toggleMenu}>
                <Link href="/account" title={t('my account')}>
                  {t('my account')}
                </Link>
              </MenuItem>
              <MenuItem onSelect={toggleMenu} className="font-extralight">
                <Link href="/account/my-orders" title={t('my orders')}>
                  {t('my orders')}
                </Link>
              </MenuItem>
              <MenuItem onSelect={toggleMenu} className="font-extralight">
                <Link href="/account/my-data" title={t('Account information')}>
                  {t('Account information')}
                </Link>
              </MenuItem>
              <MenuItem onSelect={toggleMenu} className="font-extralight">
                <ButtonMain className="mt-2" variant="Text" onClick={handleLogout}>
                  {t('Logout')}
                </ButtonMain>
              </MenuItem>
            </MenuList>
          </Menu>
          <CioUserScript session={session} />
        </>
      )}
    </>
  )
}
